@import "../../shared/variables.scss";


@include spinner;


// Breakpoints we want
// 1565px - 3 a row
// 1100px - 2 a row
// < 1100px - 1 a row

.lobbyTitle {
    font-size: 6vh;
    margin-bottom: 2vh;
}

.games {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    gap: 3vh;

    .game {
        /*flex-basis: 33.33333%;*/

        /*@media (min-width: 1214px) {
            flex-basis: 50%;
        }

        @media (min-width: 1549px) {
            flex-basis: 33.33333%;
        }

        @media (min-width: 1700px) {
            flex-basis: 25%;
        }*/
    }
}

.gameLobbyBackground {
    /*min-height: calc(100vh - $menu-height);*/
    min-height: calc(100vh);
    display: flex !important;
    align-items: center;
    justify-content: center;


    .gameLobby {
        margin-left: 47.5vh;
        padding: $spacing-md $spacing-xs;
        /*position: relative;*/
    }
}


.notificationsContainer {
    position: absolute;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    top: 2%;
    left: 480px;
    z-index: 2;

    .notification {
        height: fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-right: auto;
        padding: 10px;
        border-radius: 20px;
        margin-bottom: 5px;
        background: black;
        font-size: 30px;
        text-align: center;
        color: white;
        font-family: 'Futura PT';
    }
}

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: 0.5;
    z-index: 20;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}



.gameMenu {
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 30;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.mobile {
        position: fixed;
    }

    p {
        font-size: 20px;
    }


    .menu {
        width: 90%;
        max-width: 50vh;
        // height: 90vh;
        // overflow-y: scroll;
        border-radius: 5vh;
        margin: auto;
        z-index: 25;
        padding: 2vh;
        max-height: 90vh;
        overflow-y: auto;
        overflow-x: unset;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-size: cover;


        .gameLogo {
            height: 15vh;
            width: auto;
            margin: auto;
            margin-bottom: $spacing-xs;
        }

        img {
            margin-bottom: $spacing-xs;
            width: 95%;
            border-radius: 2vh;
        }
    }

    .infoWrapper {
        width: 95%;
        height: fit-content;
        background-color: rgba(0,0,0,0.25);
        padding: 1.5vh;
        border-radius: 2vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vh;
        margin: auto;
    }

    .gameInfo {
        display: flex;
        min-width: 100%;
        /*background: rgba($white, 0.6);*/
        padding: 0.5vh;
    }

    .gameMetrics {
        display: flex;
        justify-content: space-between;
        width: 75%;
        margin: auto;

        li {
            color: $white;
            font-size: 2vh;
            font-family: $font-secondary;
        }

        .icon {
            scale: 1;
        }

        span {
            font-family: $font-secondary;
            /*color: $black;*/
            font-size: 2vh;
        }
    }

    .gameDescription {
        /*flex-basis: 200%;*/
        align-self: center;
        text-align: left;
        width: 100%;
        padding-left: 2.5%;
        font-size: 2.5vh;
        font-weight: bold;
        text-align: center;

        .descriptionHeader {
            font-family: $font-secondary;
            margin-bottom: 5px;
        }

        .descriptionBody {
        }
    }
}

.buttonWrap {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: $spacing-sm;
}

.pageProblem {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include abs-center;
}

.loadingBox {
    display: flex;
    flex-direction: column;
    @include abs-center;
    justify-content: center;
    align-items: center;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 4px solid $white;
  }
  
  .separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: .25em;
  }

.screenOverlay {
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 32;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .screenOverlayBG {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.7;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}



.button {
    @include disable-select;
    margin-bottom: 1vh;
    display: inline-block;
    padding: (1vh / 2) 2vh;
    font-size: 3vh;
    font-family: $font-secondary;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: $white;
    background-color: #65BECD;
    border: none;
    border-radius: 1vh;
    box-shadow: 0 1vh #007DA5;
    transition: transform $speed-fast, box-shadow $speed-fast;

    &:hover {
        box-shadow: 0 0.6vh #007DA5;
        transform: translateY(4px);
    }

    &:active {
        box-shadow: 0 0.1vh #007DA5;
        transform: translateY(8px);
    }

    &.alternate {
        background-color: $hot-salmon;
        box-shadow: 0 1vh #B3434B;

        &:hover {
            box-shadow: 0 0.6vh #B3434B;
            transform: translateY(4px);
        }

        &:active {
            box-shadow: 0 0.1vh #B3434B;
            transform: translateY(8px);
        }
    }

    &.wide {
        min-width: 100%;
    }

    &.medium {
        font-size: 2.5vh;
        padding: (1vh / 2) 1.5vh;
    }

    &.small {
        font-size: 2vh;
        padding: (1vh / 2) 1vh;
    }

    &:disabled {
        opacity: 0.5;

        &:hover {
            box-shadow: 0 1vh #007DA5;
            transform: none;
        }
    }
}