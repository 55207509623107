body {
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.background {
        position: absolute;
        width: 100vw;
        height: 100vh;
}

.logo {
    position: absolute;
    left: 3vh;
    top: 3vh;
    margin: auto;
    width: 12%;
    height: 12%;
    background-image: url(../images/cos-logo.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.roomCode {
    position: absolute;
    right: 3vh;
    top: 1vh;
    margin: auto;
    display: flex;
    flex-direction: row;

    .textBox {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: fit-content;
        height: fit-content;

        .text {
            text-align: end;
            font-size: 3.25vh;
            font-family: "Interstate-Regular";

            &:nth-child(1) {
                transform: translateY(1vh);
            }

            &:nth-child(2) {
                transform: translateY(-1vh);
            }

            &.code {
                font-family: "Juri-Zaech";
                font-size: 4.5vh;
                font-weight: normal;
            }
        }

        .iconsBox {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            display: flex;
            flex-direction: row;
            width: fit-content;
            height: fit-content;
            z-index: 1000;

            .muteToggle {
                width: fit-content;
                height: fit-content;
                position: relative;
                margin: auto;
                cursor: pointer;
                padding-left: 1vh;

                .muteIcon {
                    width: auto;
                    height: 5vh;
                }
            }
        }
    }

    .qrCodeBox {
        background-color: white;
        width: fit-content;
        height: fit-content;
        margin: auto;
        padding: 1vh;
        display: flex;
        border-radius: 1vh;
        margin-left: 1vh;
        margin-top: 1vh;

        .qrCode {
            height: 14vh !important;
            width: 14vh !important;
            margin: auto;
        }
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .background {
        position: absolute;
    }

    .timerSection {
        position: absolute;
        width: fit-content;
        height: fit-content;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        right: 5vw;
        top: 17vh;
        transition: 1s;

        .timerBg {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 2vh;
            width: 26vh;
            height: 60%;
            background-color: #212B3D;
            border-top-left-radius: 2vh;
            border-bottom-left-radius: 2vh;
        }


        &.hideTimer {
            transform: translateX(26vh);
        }

        .timerText {
            text-align: center;
            font-size: 6vh;
            font-family: "Juri-Zaech";
            height: fit-content;
            margin: auto;
            position: absolute;
            top: 4vh;
            left: 13vh;
            color: white;
            transform: translateY(-0.75vh );
        }
    }

    .bannerContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        z-index: 20;
        touch-action: none;
        user-select: none;


        @keyframes bannerAnim {
            0% {
                transform: translate(-220vw, -10vh);
            }

            15% {
                transform: translate(-60vw, 0vh);
            }


            85% {
                transform: translate(-60vw, 0vh);
            }

            100% {
                transform: translate(220vw, 10vh);
            }
        }

        &.play {

            .bannerBg {
                animation-name: bannerAnim;
                animation-duration: 5s;
                animation-iteration-count: 1;
            }

            .bannerInfo {
                animation-name: bannerTextAnim;
                animation-duration: 5s;
                animation-iteration-count: 1;
            }
        }

        .bannerBg {
            width: 200%;
            height: 100%;
            position: absolute;
            transform: translate(-220vw, -10vh);
            background-repeat: no-repeat;
            filter: drop-shadow(0px 3.5vh 0px rgba(0,0,0,0.5));
        }

        .bannerInfo {
            width: 75%;
            height: fit-content;
            margin: auto;
            z-index: 1;
            transform: translate(-100vw, -10vh) rotate(6deg);
            transition: linear;
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;

            .bannerTitle {
                font-family: "Juri-Zaech";
                font-size: 16.5vh;
                text-align: center;
                width: 85%;
                color: white;
            }
        }

        @keyframes bannerTextAnim {
            0% {
                transform: translate(-100vw, -10vh) rotate(6deg);
            }

            15% {
                transform: translate(-100vw, -10vh) rotate(6deg);
            }

            35% {
                transform: translate(0, 0) rotate(6deg);
            }


            80% {
                transform: translate(0, 0) rotate(6deg);
            }

            100% {
                transform: translate(100vw, 10vh) rotate(6deg);
            }
        }
    }

    .headerSection {
        width: 100%;
        height: 30vh;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        z-index: 11;

        .counterSection {
            width: 40%;
            height: fit-content;
            margin: auto;
            display: flex;
            flex-direction: row;
            margin-bottom: 1vh;
            transition: all 1s linear;
            position: absolute;
            left: 0;
            right: 0;

            &.hidden {
                transform: translateY(-11vh);
            }

            .counterText {
                text-align: center;
                font-size: 5vh;
                font-family: "Juri-Zaech";
                margin-left: auto;
                padding-bottom: 1vh;
            }

            .tickList {
                width: fit-content;
                margin-right: auto;
                display: flex;
            }
        }

        .questionBox {
            margin: auto;
            width: fit-content;
            max-width: 50%;
            height: fit-content;
            border-radius: 20px;
            display: flex;
            background: rgb(221,221,221);
            background: linear-gradient(153deg, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 100%);
            -webkit-box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);
            box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);
            padding: 2.5vh;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transform: scale(1.5);
            transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

            &.shrunk {
                transform: translateY(-30vh) scale(1);
                transition: all 0.5s linear;
            }

            &.vanish {
                opacity: 0;
            }

            &.hidden {
                opacity: 0;
                transform: scale(0);
            }

            .questionText {
                font-size: 5vh;
                text-align: center;
                margin: auto;
                font-family: "Interstate-Regular";
            }
        }
    }




    .vsSection {
        width: 75%;
        height: 50vh;
        margin: auto;
        display: flex;
        flex-wrap: nowrap;
        z-index: 3;
        position: absolute;
        left: 0;
        right: 0;
        top: 5vh;
        bottom: 0;

        .vsPlayerSection {
            width: 40%;
            height: 80%;
            margin: auto;
            position: relative;
            display: flex;

            .playerAnchor {
                position: absolute;
                bottom: 0;

                &.one {
                    right: 13vh;
                    bottom: 20vh;

                    &.first {
                        right: 30vh;
                        bottom: 40vh;
                    }
                }

                &.two {
                    left: -6vh;
                    bottom: 20vh;

                    &.first {
                        left: 0vh;
                        bottom: 40vh;
                    }
                }

                .potato {
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 20vh;
                    opacity: 1;

                    &.hidden {
                        opacity: 0;
                    }
                }
            }

            .bigName {
                position: absolute;
                /*font-size: 10vh;*/
                font-family: "Juri-Zaech";
                bottom: 1vh;
                margin: auto;
                transition: 0.5s;
                opacity: 0;
                text-align: center;

                &.show {
                    opacity: 1;
                }

                &.left {
                    right: 0;
                    left: 0;
                    transform: translateX(7vw);

                    &.smaller {
                        font-size: 6vh;
                        bottom: -5vh;
                        right: -22vh;
                    }
                }

                &.right {
                    left: 0;
                    right: 0;
                    transform: translateX(-7vw);

                    &.smaller {
                        font-size: 6vh;
                        bottom: -5vh;
                        left: -22vh;
                    }
                }
            }

            .flipCard {
                background-color: transparent;
                width: 85%;
                height: 80%;
                perspective: 1000px; /* Remove this if you don't want the 3D effect */
                position: relative;
                transition: all 0.5s cubic-bezier(0.5, 1, 0.89, 1);
                display: flex;
                margin: auto;

                &.vanish {
                    opacity: 0;
                }

                &.left {
                    transform: rotate(-7.5deg);

                    &.hidden {
                        transform: translateX(-100vh) rotate(-70deg);
                    }

                    .flipCardInner {
                        &.doFlip {
                            transform: rotateY(180deg);
                        }
                    }
                }

                &.right {
                    transform: rotate(7.5deg);

                    &.hidden {
                        transform: translateX(100vh) rotate(70deg);
                    }

                    .flipCardInner {
                        &.doFlip {
                            transform: rotateY(-180deg);
                        }
                    }
                }

                .flipCardInner {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    transition: transform 0.8s;
                    transform-style: preserve-3d;
                }



                .playerBox {
                    position: absolute;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    -webkit-backface-visibility: hidden; /* Safari */
                    backface-visibility: hidden;
                    display: flex;
                    -webkit-box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);
                    box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);

                    &.cardFront {
                        background-color: #EEFF53;
                    }

                    &.cardBack {
                        background: rgb(221,221,221);
                        background: linear-gradient(153deg, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 100%);
                        transform: rotateY(180deg);
                    }

                    .boxLogo {
                        height: 50%;
                        width: auto;
                        margin: auto;
                    }


                    .playerText {
                        font-size: 8vh;
                        text-align: center;
                        margin: auto;
                        position: absolute;
                        font-family: "Juri-Zaech";
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        height: -moz-fit-content;
                        height: fit-content;
                        transition: all 0.5s linear;
                        overflow-wrap: anywhere;

                        &.lower {
                            bottom: -2vh;
                        }
                    }

                    .choseText {
                        font-size: 4.5vh;
                        text-align: center;
                        margin: auto;
                        position: absolute;
                        font-family: "Juri-Zaech";
                        left: 0;
                        right: 0;
                        top: 2vh;
                        height: -moz-fit-content;
                        height: fit-content;
                        transition: all 0.5s linear;
                        overflow-wrap: anywhere;
                    }
                }
            }



            .notesBubble {
                position: absolute;
                background-color: #212B3D;
                border-radius: 5vh;
                padding: 1vh;
                padding-left: 3vh;
                padding-right: 3vh;
                display: flex;
                width: fit-content;
                right: 0;
                left: 0;
                top: 32vh;
                margin: auto;
                max-width: 30vh;
                opacity: 0;
                transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                &.one {
                    transform-origin: top right;
                    transform: scale(0.25) rotate(80deg);
                }

                &.two {
                    transform-origin: top left;
                    transform: scale(0.25) rotate(-80deg);
                }

                &.show {
                    opacity: 1;
                    transform: scale(1) rotate(0deg) !important;
                }

                .cornerTick {
                    background-image: url(../images/bubbleTick.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    position: absolute;
                    width: 5vh;
                    height: 5vh;
                    top: -1vh;

                    &.left {
                        transform: scaleX(-1);
                        right: -2.8vh;
                    }

                    &.right {
                        left: -2.8vh;
                    }
                }

                .notesText {
                    font-size: 2.75vh;
                    text-align: center;
                    margin: auto;
                    font-family: "Interstate-Regular";
                    color: white;
                    overflow-wrap: anywhere;
                }
            }
        }

        .vsBox {
            margin: auto;
            width: 20%;
            height: 50%;
            display: flex;
            transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
            opacity: 0;
            transform: scale(0);

            &.show {
                opacity: 1;
                transform: scale(1);
            }

            &.vanish {
                opacity: 0;
            }

            .vsImg {
                height: 18vh;
                margin: auto;
            }
        }
    }

    .playersSection {
        width: 90%;
        height: 30vh;
        margin: auto;
        display: flex;
        z-index: 2;
        transition: all 1s linear;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5vh;
        z-index: 10;

        &.hidden {
            transform: translateY(25vh);
        }

        &.hideNames {
            transform: translateY(5.75vh);
        }
    }

    .winnerBox {
        position: absolute;
        left: 0;
        right: 0;
        top: 15vh;
        margin: auto;
        width: fit-content;
        max-width: 50vw;
        height: fit-content;
        padding: 3vh;
        border-radius: 5vh;
        display: flex;
        flex-direction: column;
        z-index: 4;
        background: rgb(221,221,221);
        background: linear-gradient(153deg, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 100%);
        -webkit-box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);
        box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);
        padding-bottom: 4.5vh;
        transition: 1s;
        transform: scale(0);

        &.show {
            transform: scale(1);
        }

        .potatoAnchor {
            position: absolute;

            &.one {
                bottom: -5vh;
                left: -2vh;
            }

            &.two {
                bottom: -5h;
                left: 5vh;
            }

            &.three {
                bottom: -5vh;
                left: 12vh;
            }

            &.four {
                bottom: -5vh;
                left: 19vh;
            }

            &.five {
                bottom: -5vh;
                left: 26vh;
            }

            &.six {
                bottom: -5vh;
                left: 33vh;
            }

            &.seven {
                bottom: -5vh;
                left: 40vh;
            }

            &.eight {
                bottom: -5vh;
                left: 47vh;
            }
        }

        .winnerText {
            font-size: 7vh;
            margin: auto;
            text-align: center;
            font-family: "Juri-Zaech";

            &.smaller {
                font-size: 5.5vh;
                font-family: "Interstate-Regular";
            }
        }
    }

    .startGameSection {
        width: 100%;
        height: fit-content;
        position: absolute;
        left: 0;
        right: 0;
        top: 13vh;
        margin: auto;
        flex-direction: column;
        display: flex;
        z-index: 30;
        transition: 1s;
        transform: translateY(-100vh);

        &.center {
            /*top: 0;*/
            bottom: 0;
        }

        &.show {
            transform: translateY(0);
        }

        .choiceButton {
            width: fit-content;
            height: fit-content;
            padding-top: 1vh;
            padding-left: 2vh;
            padding-right: 2vh;
            padding-bottom: 3.5vh;
            margin: auto;
            font-size: 9vh;
            font-family: "Juri-Zaech";
            color: white;
            background-color: #212B3D;
            border-radius: 2vh;
            box-shadow: 0px 6px 0px 0px #101c2d;
            border: none;
            user-select: initial !important;
            cursor: pointer;
            margin-bottom: 2vh;


            &.smaller {
                /*width: 20%;
                height: 26%;*/
                font-size: 5vh;
                padding-bottom: 2.5vh;
            }
        }

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            /*margin-top: -4vh;*/

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #212B3D;
                margin: 0;
                font: inherit;
                width: 7.5vh;
                height: 7.5vh;
                border-radius: 2vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: #101C2D !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 5.5vh;
                height: 5.5vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: white;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 2vh;
                font-size: 5.5vh;
                border-radius: 2vh;
                font-family: 'Interstate-Regular';
                color: #212529;
                margin: auto;
            }
        }
    }

    .playAgainSection {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        margin-bottom: unset;
        position: absolute;
        transform: translateY(100vh);
        transition: 1s;
        z-index: 30;

        &.show {
            transform: translateY(0);
        }

        .buttonsSection {
            width: 35%;
            height: fit-content;
            margin: auto;
            display: flex;
            flex-direction: column;
            margin-top: 30vh;
            margin-left: 15%;

            .choiceButton {
                width: fit-content;
                height: fit-content;
                padding: 2vh;
                margin: auto;
                font-size: 9vh;
                font-family: "Juri-Zaech";
                color: white;
                background-color: #212B3D;
                border-radius: 2vh;
                box-shadow: 0px 6px 0px 0px #101c2d;
                padding-bottom: 4vh;
                padding-left: 3vh;
                padding-right: 3vh;
                border: none;
                user-select: initial !important;
                cursor: pointer;


                &.smaller {
                    /*width: 20%;
                height: 26%;*/
                    font-size: 5vh;
                    padding-bottom: 3vh;
                    margin-top: 3vh;
                }
            }
        }


        .leaderboardSection {
            width: 50%;
            height: 75%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            margin-top: auto;
            justify-content: center;
            align-items: start;
            padding-left: 5vh;
            margin-bottom: 7.5vh;

            .boardRow {
                width: 70%;
                height: fit-content;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                border-radius: 1vh;
                position: relative;
                margin-bottom: 1.25vh;
                margin-top: 1.25vh;
                border-radius: 1vh;
                background-color: white;
                border-radius: 50px;

                .numberBox {
                    color: white;
                    background-color: #212B3D;
                    width: 10%;
                    min-height: 100%;
                    display: flex;
                    border-bottom-left-radius: inherit;
                    border-top-left-radius: inherit;

                    .positionText {
                        text-align: center;
                        /*line-height: 1;*/
                        font-family: "Juri-Zaech";
                        font-size: 3vh;
                        margin: auto;
                        margin-bottom: 0.75vh;
                    }
                }

                .nameBox {
                    text-align: start;
                    /*line-height: 1;*/
                    font-family: "Juri-Zaech";
                    width: 60%;
                    font-size: 3vh;
                    padding-left: 1vh;
                    margin-bottom: 0.75vh;
                }

                .scoreBox {
                    width: 30%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    .text {
                        font-family: "Juri-Zaech";
                        font-size: 3vh;
                        margin-bottom: 0.5vh;

                        &.smaller {
                            font-size: 2vh;
                            padding-bottom: 0.3vh;
                            padding-left: 0.5vh;
                            margin-top: auto;
                        }
                    }
                }

                .potato {
                    width: 10vh;
                    height: auto;
                    margin: auto;
                    position: absolute;
                    right: -5.5%;
                    top: -2.75vh;
                }
            }
        }
    }
}
