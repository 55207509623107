.player {
    width: 10%;
    height: 65%;
    margin: auto;
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    transition: all 2s linear;

    .answerSection {
        position: absolute;
        display: flex;
        opacity: 0;
        padding: 0.5vh;
        border-radius: 20px;
        left: 12vh;
        bottom: 15vh;
        height: 5.5vh;
        align-items: start;
        transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
        transform-origin: bottom left;
        transform: rotate(80deg) scale(0.25);

        &.show {
            opacity: 1;
            transform: rotate(0) scale(1);
        }

        .answerImg {
            height: fit-content;
            width: fit-content;
            margin: auto;
            z-index: 1;
            background-color: #212B3D;
            font-size: 2.1vh;
            border-radius: 5vh;
            color: white;
            font-family: "Juri-Zaech";
            line-height: 1;
            padding-bottom: 1.2vh;
            padding-left: 1.5vh;
            padding-right: 1.5vh;
            padding-top: 0.5vh;
            max-width: 14vh;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;


            &.show {
                transform: rotate(0) scale(1);
                opacity: 1;
            }

            &.correct {
                color: #00FF00;
            }

            &.wrong {
                color: #EF6A5E;
            }
        }
    }

    .corner {
        position: absolute;
        width: 0;
        height: 0;
        border-top: 0.5vh solid transparent;
        border-right: 2vh solid #212B3D;
        border-bottom: 0.5vh solid transparent;
        margin: auto;
        bottom: 0.5vh;
        left: -0.35vh;
        transform: rotate( -43deg);
        z-index: -1;
    }

    .bonusAnswer {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        width: -moz-fit-content;
        width: fit-content;
        bottom: -2vh;
        font-size: 1.75vh;
        border-radius: 5vh;
        padding-left: 0.75vh;
        padding-right: 0.75vh;
        background: rgb(221,221,221);
        background: linear-gradient(153deg, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 100%);
        display: none;
        font-family: "Juri-Zaech";
        line-height: normal;
        padding-bottom: 0.5vh;

        &.show {
            display: flex;
        }
    }


    .readySection {
        position: absolute;
        display: flex;
        opacity: 0;
        padding: 0.5vh;
        border-radius: 5vh;
        left: 0;
        right: 0;
        bottom: 19vh;
        background: #212b3d;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        padding-left: 2vh;
        padding-right: 2vh;
        padding-bottom: 1vh;


        &.show {
            opacity: 1;
        }

        .readyText {
            font-size: 2.5vh;
            margin: auto;
            font-family: "Juri-Zaech";
            color: white;
            line-height: normal;
        }
    }

    .countdownContainer {
        height: 14.5vh;
        margin: auto;
        position: absolute;
        left: 1.75vh;
        top: 1.5vh;
        font-family: "Interstate-Regular";
        font-size: 2vh;
        text-align: center;
    }


    .potato {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 14.5vh;
        width: 14.5vh;
        margin: auto;
        position: absolute;
        left: 0;
        top: 1.5vh;
        transition: 0.75s;
        display: flex;
        flex-direction: column;

        &.bigger {
            width: 30vh !important;
            height: 30vh !important;
        }

        &.centered {
            left: 50%;
            transform: translateX(-50%);
        }

        &.fade {
            opacity: 0.3;
        }

        &.hidden {
            opacity: 0;
        }

        .bigName {
            position: absolute;
            /*font-size: 10vh;*/
            font-family: "Juri-Zaech";
            bottom: -10vh;
            margin: auto;
            transition: 0.75s;
            opacity: 0;
            text-align: center;
            left: -100%;
            right: -100%;

            &.show {
                opacity: 1;
            }

            &.smaller {
                bottom: -5vh;
            }
        }
    }

    .textSection {
        width: fit-content;
        padding-right: 2vh;
        padding-left: 2vh;
        margin-left: auto;
        margin-right: auto;
        margin-Top: auto;
        display: flex;
        flex-wrap: nowrap;
        background-color: white;
        border-radius: 5vh;
        line-height: normal;

        &.colorSwap {
            background-color: transparent;
        }

        .nameText {
            font-size: 4vh;
            margin: auto;
            font-family: 'Pintassilgo';

            &.colorSwap {
                color: white;
            }

            &.large {
                font-size: 4vh;
            }

            &.medium {
                font-size: 3vh;
            }

            &.small {
                font-size: 2vh;
            }

            &.score {
                margin-left: 1.5vh;
            }
        }
    }

    .podium {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 14.5vh;
        display: none;
        background-color: #212B3D;
        border-radius: 2vh;
        z-index: -1;
    }
}
