@font-face {
    font-family: "Interstate-Bold";
    src: url(/fonts/Interstate-Bold.ttf) format('truetype');
}

@font-face {
    font-family: "Interstate-Regular";
    src: url(/fonts/Interstate-Regular.otf) format('opentype');
}
@font-face {
    font-family: "Juri-Zaech";
    src: local('Asap'), url('/fonts/JuriZaech-FrontageCondensedBold.otf') format('opentype');
}
@font-face {
    font-family: "Pintassilgo";
    src: local('Asap'), url('/fonts/PintassilgoPrints-UnpackRegular.ttf') format('truetype');
}