body {
}


:global(div) {
    touch-action: manipulation;
}

:global(img) {
    touch-action: manipulation;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}



.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 600px !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .connectionIssueContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        z-index: 10000;
        justify-content: center;
        align-items: center;

        .connectionText {
            width: fit-content;
            height: fit-content;
            font-size: 30px;
            color: white;
            font-family: "Interstate-Regular";
            text-align: center;
        }

        .refreshButton {
            font-family: 'Lucida Sans Unicode';
            padding: 10px;
            line-height: 1;
            font-weight: bolder;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            width: fit-content;
            height: fit-content;
            font-size: 75px;
            color: white;
            background-color: #212B3D;
            border-radius: 20px;
            box-shadow: 0px 6px 0px 0px #101c2d;
            cursor: pointer;
        }
    }

    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
            font-family: "Juri-Zaech";
        }
    }

    .roundTimer {
        position: absolute;
        margin: auto;
        right: 2vh;
        bottom: 2vh;
        font-size: 5vh;
        font-family: "Interstate-Regular";
        text-align: center;
    }

    .headerSection {
        width: 90%;
        height: fit-content;
        padding: 5px;
        margin: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        border-radius: 15px;
        background: rgb(221,221,221);
        background: linear-gradient(153deg, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 100%);
        -webkit-box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);
        box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);
        z-index: 1;
        margin-top: 25px;
        margin-bottom: 2.5%;
        max-width: 500px;

        .questionText {
            font-size: 22.5px;
            text-align: center;
            margin: 1%;
            font-family: "Interstate-Regular";
        }
    }



    .buttonContainer {
        margin: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        margin-top: unset;
        max-width: 500px;

        .smallerText {
            font-size: 20px;
            text-align: center;
            margin: 2%;
            margin-top: unset;
            margin-bottom: 3%;
            line-height: 1;
            font-family: "Juri-Zaech";
        }

        .buttonSection {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            .answerButton {
                margin: auto;
                margin-bottom: 3%;
                width: fit-content;
                min-width: 85%;
                max-width: 85%;
                height: 130px;
                font-size: 45px;
                padding-top: 1%;
                line-height: 1;
                color: white;
                background-color: #212B3D;
                border-radius: 15px;
                box-shadow: 0px 6px 0px 0px #101c2d;
                font-family: "Juri-Zaech";
                border: none;
                cursor: pointer;
                outline: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-bottom: 2%;

                &.selected {
                    background-color: #ef6a5e;
                }

                &.smaller {
                    height: 80px;
                    font-size: 25px;
                    margin-top: 3%;
                }
            }
        }



        .bonusSection {
            margin: auto;
            width: -moz-fit-content;
            width: fit-content;
            min-width: 90%;
            height: 25%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-left: 50%;
            transform: translate(-50%);


            .bonusTitle {
                text-align: center;
                font-size: 2.5vh;
                margin: auto;
                margin-bottom: 0;
            }

            .bonusButton {
                margin: auto;
                width: -moz-fit-content;
                width: fit-content;
                min-width: 45%;
                height: 69%;
                font-size: 3vh;
                padding: 1vh;
                padding-bottom: 2vh;
                color: white;
                background-color: #212B3D;
                border-radius: 1.5vh;
                box-shadow: 0px 6px 0px 0px #101c2d;
                font-family: "Juri-Zaech";
                border: none;
                /*user-select: initial !important;*/
                cursor: pointer;

                &.bonusSelected {
                    background-color: #ef6a5e;
                }
            }
        }
    }

    .descBox {
        margin: auto;
        width: 70%;
        text-align: center;
        border: none;
        border-radius: 15px;
        font-size: 20px;
        font-family: 'Interstate-Regular';
        height: 100px;
        margin-top: 3%;
        outline: none;
        max-width: 400px;
    }

    .submitButton {
        width: fit-content;
        height: 60px;
        margin: auto;
        font-size: 35px;
        color: white;
        background-color: #212B3D;
        padding-bottom: 2%;
        border-radius: 15px;
        line-height: 1;
        box-shadow: 0px 6px 0px 0px #101c2d;
        font-family: "Juri-Zaech";
        border: none;
        cursor: pointer;
        margin-top: 5%;
        margin-bottom: 2.5%;
        outline: none;
    }
}

.skipContainer {
    background-color: #212B3D;
    border-radius: 1vh;
    width: fit-content;
    height: fit-content;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    .skipButton {
        font-family: "Juri-Zaech";
        font-size: 4vh;
        color: white;
        text-align: center;
        padding: 1vh;
        margin: auto;
        position: relative;
        bottom: 1vh;
    }
}

.animationSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .animationsTitle {
        font-size: 3vh;
        text-align: center;
        margin: auto;
    }

    .animationsContainer {
        width: 90%;
        height: 80%;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .animation {
            cursor: pointer;
            width: 40%;
            margin: auto;
            border: solid 0.75vh black;
            border-radius: 0.5vh;
            font-size: 2vh;
            text-align: center;
            padding: 6%;

            &:active {
                transform: scale(1.25);
            }

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}

.readySection {
    width: 100%;
    height: 15vh;
    position: absolute;
    bottom: 0;
    display: flex;
    border-top-right-radius: 2vh;
    border-top-left-radius: 2vh;
    background: rgb(221,221,221);
    background: linear-gradient(153deg, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 100%);
    -webkit-box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);
    box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.33);

    .readyButton {
        width: fit-content;
        height: fit-content;
        padding-top: 1vh;
        padding-left: 3vh;
        padding-right: 3vh;
        padding-bottom: 2vh;
        margin: auto;
        position: relative;
        font-size: 4vh;
        font-family: "Juri-Zaech";
        text-align: center;
        color: white;
        background-color: #212B3D;
        border-radius: 2vh;
        box-shadow: 0px 6px 0px 0px #101c2d;

        &:active {
            transform: scale(1.25);
        }
    }
}

